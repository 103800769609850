<template>
  <Tile z="1" id="GameSettings">
    <h2>Advanced Settings</h2>
    <InputCheckbox :default="getSettings().acceptingPlayers" :inputData="acceptingPlayers" @update="updateAllowPlayers($event);" placeholder="Allow Players To Join"></InputCheckbox>
    <Button :callback="submit" style="display: none;">
      Save
    </Button>
  </Tile>
</template>

<script>
  import Tile from "@/components/Tile.vue";
  import Button from "@/components/Button.vue";
  import InputCheckbox from "@/components/InputCheckbox.vue";
  export default {
    name: 'GameSettings',
    components: {
      Button,
      Tile,
      InputCheckbox,
    },
    data: () => {
      return {
        acceptingPlayers: false,
      }
    },
    methods: {
      async submit() {

      },
      async updateAllowPlayers(shouldAllow) {
        this.acceptingPlayers = shouldAllow;
        
        this.$store.commit('change_game_settings', {
          acceptingPlayers: this.acceptingPlayers
        });
      },
      getSettings() {
        console.log(this.$store.state.game.game_obj.settings);
        return this.$store.state.game.game_obj.settings;
      }
    }
  }
</script>
<style scoped lang="less">
#GameSettings {
  padding-bottom: 30px !important;
}
</style>