<template>
  <div class="Tabs">
    <div class="header">
      <div class="header--tab" :class="{active: activeTab === 'tab1'}" @click="setActive('tab1');">
        <slot name="tab1--title"></slot>
      </div>
      <div class="header--tab" :class="{active: activeTab === 'tab2'}" @click="setActive('tab2');">
        <slot name="tab2--title"></slot>
      </div>
    </div>
    <div class="body">
      <div class="body--content" :class="{active: activeTab === 'tab1'}">
        <slot name="tab1">
        </slot>
      </div>
      <div class="body--content" :class="{active: activeTab === 'tab2'}">
        <slot name="tab2">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Counter',
    props: ['counter', 'value'],
    components: {
    },
    data: () => {
      return {
        activeTab: "tab1",
      }
    },
    methods: {
      setActive(tabName) {
        this.activeTab = tabName;
      }
    }
  }
</script>

<style scoped lang="less">
.Tabs {
  display: flex;
  flex-flow: column;
  width: 100%;

  .header {
    display: flex;
    flex-flow: row;
    cursor: pointer;
    width: 100%;

    &--tab {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      color: white;

      i {
        margin-left: 5px;
        margin-right: 5px;
      }

      &.active {
        color: @app-color-primary-1;
        border-bottom: 1px solid @app-color-primary-1;
      }
    }
  }

  .body {
    padding: 0;

    &--content {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}
</style>
