<template>
  <div class="profile">
    <Tabs>
      <template v-slot:tab1--title>
        <i class="fa fa-user"></i>Profile Settings
      </template>
      <template v-slot:tab1>
        <h2>Profile Settings</h2>
        <Player
          name="player"
          :key="player.publicId"
          :life="player.life"
          :game="$store.state.game.game_obj"
          :player="player">
        </Player>

        <Tile z="1">
          <h2>Player Info</h2>
          <ProfileCreator
            ref="creator"
            @validity="isValid=$event"
            v-bind:name="$store.state.player.name"
            v-bind:avatar="$store.state.player.avatar"></ProfileCreator>
          <Button :enabled="isValid" :processing="isProcessing" :callback="setPlayerInfo">Save</Button>
        </Tile>
      </template>
      <template v-slot:tab2--title>
        <i class="fa fa-cog"></i>Game Settings
      </template>
      <template v-slot:tab2>
        <h2>Game Settings</h2>
        <Tile z="1">
          <h2>Game Code</h2>
          <QRCode :gameId="$store.state.game.game_obj.publicId"></QRCode>
          <Button v-if="$store.state.game.game_obj.hostId === $store.state.player.publicId" :callback="resetGame">
            Reset Game
          </Button>
        </Tile>
        <!-- ADD THING -->
        <GameSettings v-if="$store.state.game.game_obj.hostId === $store.state.player.publicId"></GameSettings>
      </template>
    </Tabs>


    
  </div>
</template>
<script>
  import Player from "@/components/Player.vue";
  import Tabs from "@/components/Tabs.vue";
  import Tile from "@/components/Tile.vue";
  import ProfileCreator from "@/views/partials/ProfileCreator.vue";
  import GameSettings from "@/views/partials/GameSettings.vue";
  import QRCode from "@/components/QRCode.vue";
  import Button from "@/components/Button.vue";
  export default {
    name: 'Profile',
    components: {
      Player,
      Tabs,
      Tile,
      ProfileCreator,
      GameSettings,
      QRCode,
      Button
    },
    computed: {
        player: function() {
            return Object.assign({}, this.$store.state.player, {
                color1: this.color1,
                color2: this.color2,
            })
        }
    },
    data: function() {
      return {
        isValid: false,
        isProcessing: false
      }
    },
    methods: {
      async setPlayerInfo() {
        this.isProcessing = true;
        
        // const data = this.$refs.creator.$data;
        await this.$store.dispatch('update_player', Object.assign(this.$refs.creator.getFinalBody(), {
          gameId: this.$store.state.player.gameId
        }));

        this.$store.commit('set_player_defaults', this.$refs.creator.getFinalBody());

        this.isProcessing = false;
      },

      resetGame() {
        const shouldReset = confirm('Are you sure you want to reset and start a new game?');

        if(shouldReset) {
          this.$store.commit('reset_game', this.$store.state.player.secretId);
        }
      }
    }
  }
</script>

<style scoped lang="less">
    .profile {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      width: 100%;
    }

    .colorInput {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
</style>